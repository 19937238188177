import { Link } from "gatsby"
import React from "react"
import { graphql, StaticQuery } from 'gatsby'

import './footer.css'

export default () => (
    <StaticQuery
        query={graphql` 
            query FooterQuery {
                allContentfulMenuLink(sort: {fields: [orderField], order: ASC}) {
                    edges {
                        node {
                            url
                            title
                            orderField
                        }
                    }
                }
            }
        `}

      render={data => (
        <footer>
            <nav className="footer-nav" >
                <div className="footer-logo-left">
                    <Link to="/"><img src={require("../images/MODS-Logo-without-text-kate-version.png")} alt='Museum of Distilled Spirits logo.' 
                    className='footer-nav-item-logo' /></Link>
                </div>

                <ul className="footer-nav-items">
                        {data.allContentfulMenuLink.edges.map(edge => (
                            <li>
                                <Link to={"/" + edge.node.url}>{edge.node.title}</Link>
                            </li>
                        ))}
                </ul>
            </nav>
            <div className="footer-bottom-group">
                <div className="copyright">
                    Copyright © {new Date().getFullYear()}, Museum of Distilled Spirits
                </div>
                <div className="follow-group">
                    <div>Follow: </div>
                    <div><a href="https://www.instagram.com/museumofdistilledspirits/"><img src={require("../images/insta-icon.png")} alt='Museum of Distilled Spirits instagram.'/></a></div>
                    <div><a href="https://lp.constantcontactpages.com/su/g8xo2a7"><img src={require("../images/email-icon.png")} alt='subscribe to receive email updates.'/></a></div>
                    <div><a href="https://www.facebook.com/MuseumOfDistilledSpirits"><img src={require("../images/fb-icon.png")} alt='facebook page.'/></a></div>
                    <div><a href="https://twitter.com/SpiritsMuseum"><img src={require("../images/twitter-icon.png")} alt='follow us on Twitter.'/></a></div>
                    <div><a href="https://www.linkedin.com/company/museum-of-distilled-spirits/"><img src={require("../images/linkedin-icon.png")} alt='LinkedIn.'/></a></div>
                    <div><a href="https://www.eventbrite.com/d/online/museum-of-distilled-spirits/?q=Museum+of+Distilled+Spirits.&mode=search"><img src={require("../images/eventbrite-icon.png")} alt='upcoming events on Eventbrite.'/></a></div>
                </div>
            </div>
         </footer>
      )}
    />
  )
