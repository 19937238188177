import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import { window } from 'browser-monads';
import './header.css'
import './sidedrawertogglebtn.css'
import SideDrawerToggleBtn from "./sidedrawertogglebtn";


class Header extends React.Component {
  constructor(props) {
    super(props)

    //initial state
    this.state = {
      hasScrolled: false
    }
  }

  //making a change using componentDidMount
  componentDidMount() {
    //listening to an event called 'scroll', then changing to function, handleScroll
    window.addEventListener('scroll', this.handleScroll)
  }

  //function, handleScroll
  handleScroll = (event) => {
    //getting data of where user has scrolled / it's position
    const scrollTop = window.pageYOffset

    //if that value is more than 50px, then set a new state
    if(scrollTop > 50) {
      //change the boolean to true
      this.setState({ hasScrolled: true })
    } else {
      //or else set the boolean to false
      this.setState({ hasScrolled: false })
    }
  }


  render() {
    return (

      <header className={this.state.hasScrolled ? 'header-scrolled' : 'header-noscrolled'} >


      <a className="skip-link" href='#maincontent'>Skip to main content</a>

        
        <nav className="header-nav">
          {/*<div className="nav-items" >*/}

          <div className=" logo logo-left">
              <Link to="/"><img src={require("../images/MODS-Logo-without-text-kate-version.png")} alt='Museum of Distilled Spirits logo.' 
              className='nav-item-logo' /></Link>
          </div>


          <ul className="main-menu nav-items" id="main-menu">

            <a href="#main-menu-toggle" id="main-menu-close" className="menu-close" area-label="Close main menu">
              <span className="sr-only">Close main menu</span>
              <img src={require("../images/close-icon.png")} alt='close mobile menu.'/>
            </a>

              {/*<li>
                  <Link className={window.location.href.indexOf('hand-sanitizer') > 0 ? 'nav-item-link active' : 'nav-item-link'} 
                  to="/hand-sanitizer">Exhibits</Link>
              </li>*/}
              <li className="dropdown">
                  <Link className={
                    window.location.href.indexOf('spirit-collections') > 0 || window.location.href.indexOf('tequila') > 0 || window.location.href.indexOf('rum')  > 0 || window.location.href.indexOf('brandy')  > 0 || window.location.href.indexOf('rum') > 0 || window.location.href.indexOf('vodka') > 0 || window.location.href.indexOf('whiskey') > 0 || window.location.href.indexOf('american-distillers-pandemic-response') > 0 ? 'nav-item-link active' : 'nav-item-link'
                    }
                      to="/spirit-collections">Collections</Link>
                  <ul className="dropdown-content" >
                      <li>
                          <Link className={window.location.href.indexOf('brandy') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/brandy">Brandy</Link>
                      </li>
                      <li>
                          <Link className={window.location.href.indexOf('gin') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/gin">Gin</Link>
                      </li>
                      <li>
                          <Link className={window.location.href.indexOf('rum') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/rum">Rum</Link>
                      </li>
                      <li>
                          <Link className={window.location.href.indexOf('tequila') > 0 ? 'nav-item-link active' : 'nav-item-link'}
                          to="/tequila">Tequila</Link>
                      </li>
                      <li>
                          <Link className={window.location.href.indexOf('whiskey') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/whiskey">Whiskey</Link>
                      </li>
                      <li>
                          <Link className={window.location.href.indexOf('vodka') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/vodka">Vodka</Link>
                      </li>
                      <li>
                          <Link className={window.location.href.indexOf('american-distillers-pandemic-response') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/american-distillers-pandemic-response">Exhibitions</Link>
                      </li>
                      <li>
                          <Link className={window.location.href.indexOf('nonconformists') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/nonconformists">Nonconformists</Link>
                      </li>
                  </ul>
              </li>
              <li className="dropdown">
                  <Link className={window.location.href.indexOf('about') > 0 || window.location.href.indexOf('team') > 0 ? 'nav-item-link active' : 'nav-item-link'}
                  to="/about">About</Link>
                  <ul className="dropdown-content">
                    <li>
                      <Link className={window.location.href.indexOf('team') > 0 ? 'nav-item-link active' : 'nav-item-link'}
                          to="/team">Team</Link>
                    </li>
                  </ul>
              </li>
              <li>
                  <Link className={window.location.href.indexOf('events') > 0 ? 'nav-item-link active' : 'nav-item-link'}
                  to="/events">Events</Link>
              </li>
              <li>
                  <Link className={window.location.href.indexOf('vr-experience') > 0 ? 'nav-item-link active' : 'nav-item-link'}
                  to="/vr-experience">VR Experience</Link>
              </li>
              <li>
                  <Link className={window.location.href.indexOf('shop') > 0 ? 'nav-item-link active' : 'nav-item-link'}
                  to="/shop">Shop</Link>
              </li>

              <li>
                  <Link className={window.location.href.indexOf('sponsor') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/sponsor">Sponsor</Link>
              </li>
              <li className="dropdown">
                  <Link className={window.location.href.indexOf('join') > 0 || window.location.href.indexOf('careers') > 0 ? 'nav-item-link active' : 'nav-item-link'} to="/join">Join</Link>
                  <ul className="dropdown-content">
                    <li>
                      <Link className={window.location.href.indexOf('careers') > 0 ? 'nav-item-link active' : 'nav-item-link'}
                          to="/careers">Careers</Link>
                    </li>
                  </ul>
              </li>
          </ul>

          <a href="#main-menu-toggle" className="backdrop" tabindex="-1" aria-hidden="true" hidden>
          </a>

          <a href="#main-menu" className="menu-toggle" area-label="Open main menu" id="main-menu-toggle">
            <span className="sr-only">Open main menu</span>
              <SideDrawerToggleBtn />
          </a>

     </nav>

    </header>
    )
  }
}

/*Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}*/

export default Header
