import React from 'react'

import './sidedrawertogglebtn.css'

const SideDrawerToggleBtn = props => (
    <button className="toggle-button" aria-hidden="true">
        <div className="toggle-btn-line" />
        <div className="toggle-btn-line" />
        <div className="toggle-btn-line" />
    </button>
)

export default SideDrawerToggleBtn